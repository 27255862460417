import PropTypes from 'prop-types';

import { useSoyuzLocales, trans, getPagePath } from '@spotahome/soyuz/client';

import Button from '../Button';
import Section from '../Section';

import NoResultsIcon from './NoResultsIcon';
import './GenericErrorContent.scss';

const GenericErrorContent = ({
  errorCode = null,
  title = 'error_page.client_error.title',
  subtitle = 'error_page.client_error.subtitle',
  onClick = null
}) => {
  const { current: currentLocale } = useSoyuzLocales();

  const handleHomeClick = () => {
    window.location = getPagePath('landing', { locale: currentLocale });
  };

  return (
    <Section className="generic-error">
      <NoResultsIcon />
      <h1>{trans(title)}</h1>
      <p className="generic-error__subtitle">
        {trans(subtitle)}
        {errorCode || null}
      </p>

      <Button
        onClick={onClick || handleHomeClick}
        size="large"
        dataTest="no-results-home-link"
        color="secondary"
        renderContent={() => trans('error_page.not_found.cta')}
      />
    </Section>
  );
};

GenericErrorContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  errorCode: PropTypes.number,
  onClick: PropTypes.func
};

export default GenericErrorContent;
