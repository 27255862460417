import PropTypes from 'prop-types';

import { HeaderContainer as HeaderContainerComponent } from '@spotahome/ui-library';

const HeaderContainer = ({ renderExtraContent = () => {} }) => (
  <HeaderContainerComponent
    className="header-menu-wrapper"
    color="light"
    showLogin
    links={{ changeCity: true }}
    renderExtraContent={renderExtraContent}
    isContactUsEnabled
  />
);

HeaderContainer.propTypes = {
  renderExtraContent: PropTypes.func
};

export default HeaderContainer;
