import axios from 'axios';

const DEFAULT_DOMAIN = '';
const API_PREFIX = '/marketplace';

export function traditionalSignup(options = {}) {
  const headers = {};
  // eslint-disable-next-line no-unused-vars
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${API_PREFIX}/traditional-signup`;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.post(path, options, { headers });
}

export function traditionalLogin(options = {}) {
  const headers = {};
  // eslint-disable-next-line no-unused-vars
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${API_PREFIX}/traditional-login`;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.post(path, options, { headers });
}

export function traditionalForgotPassword(options = {}) {
  const headers = {};
  // eslint-disable-next-line no-unused-vars
  const { cookie, domain = DEFAULT_DOMAIN } = options;
  const path = `${API_PREFIX}/forgotten-password`;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.post(path, options, { headers });
}

export function resendActivationUser(id) {
  const path = `${API_PREFIX}/resend-activation`;

  return axios.post(path, { id });
}

export function setNewPassword(id, password) {
  const path = `${API_PREFIX}/set-new-password`;

  return axios.post(path, { id, password });
}

export default {
  traditionalSignup,
  traditionalLogin,
  traditionalForgotPassword,
  resendActivationUser,
  setNewPassword
};
