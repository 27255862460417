const NoResultsIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 42.5V100H35.3507V75.3686C35.3507 67.2114 41.9231 60.5737 50 60.5737C58.0769 60.5737 64.6493 67.2114 64.6493 75.3686V100H85V42.5H15Z"
      fill="#FFCEF4"
    />
    <path
      d="M63.125 42.5C67.2671 42.5 70.625 39.1421 70.625 35C70.625 30.8579 67.2671 27.5 63.125 27.5C58.9829 27.5 55.625 30.8579 55.625 35C55.625 39.1421 58.9829 42.5 63.125 42.5Z"
      fill="#0A0777"
    />
    <path
      d="M12.5 32.2206C20.9182 43.7681 37.8742 45.9075 50.407 37.0034C62.9397 28.0994 66.2697 11.5475 57.8515 0L12.5 32.2206Z"
      fill="#FB6E44"
    />
  </svg>
);

export default NoResultsIcon;
