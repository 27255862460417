import { useSoyuzData } from '@spotahome/soyuz/client';

import {
  ThemeProvider,
  FooterSimplified,
  UserInfoProvider,
  GenericErrorContent
} from '@spotahome/ui-library';

import HeaderContainer from './components/HeaderContainer';
import LoginModal from './components/LoginModal';

import './scss/init.scss';

const GenericErrorPage = () => {
  const { [GenericErrorPage.NAME]: requestedData } = useSoyuzData();
  const { errorType = 'clientError', errorCode } = requestedData.query;

  const errorCodeNum = parseInt(errorCode, 10) || null;

  let title;
  let subtitle;
  if (errorType === 'notFound') {
    title = 'error_page.not_found.h1';
    subtitle = 'error_page.not_found.content';
  }

  return (
    <ThemeProvider flags={{ newButtons: true }}>
      <UserInfoProvider>
        <HeaderContainer />
        <GenericErrorContent
          title={title}
          subtitle={subtitle}
          errorCode={errorCodeNum}
        />
        <FooterSimplified />
        <LoginModal />
      </UserInfoProvider>
    </ThemeProvider>
  );
};

GenericErrorPage.NAME = 'GenericErrorPage';

GenericErrorPage.requestAllData = req => {
  return {
    data: {
      query: req.query
    }
  };
};

export default GenericErrorPage;
